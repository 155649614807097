<template>
  <b-card
    no-body
    class="target-ad-collapse"
    :class="{ open: visible }"
  >
    <b-card-header
      ref="myCollapsible"
      :class="{ collapsed: visible }"
      :aria-expanded="visible ? 'true' : 'false'"
      :aria-controls="collapseItemID"
      role="tab"
      data-toggle="collapse"
      @click="collapseOpen(!visible, $event)"
    >
      <slot name="header">
        <div class="d-flex flex-row justify-content-between w-100">
          <div class="name-class">
            <b-link class="d-flex justify-content-between align-items-center ">
              <span
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-tooltip.hover.left="title"
                class="d-block title-class text-truncate"
              >
                {{ title }}
              </span>
            </b-link>
          </div>
          <div class="d-flex flex-row justify-content-between w-65">
            <div>
              <div
                v-if="spinner[id]"
                style="width: 75px;"
                class="spinner text-center"
              >
                <b-spinner
                  variant="primary"
                  small
                />
              </div>
              <div
                class="time"
              >
                <b-img
                  size="16px"
                  :src="require('@/assets/images/simiicons/wall-clock.svg')"
                />
                {{ time | moment }} to {{ time | targetAdsUptoTimeMoment }}
              </div>
            </div>
            <div class=" d-flex flex-row align-items-center mt-1 mt-md-0">
              <div>
                <b-badge
                  v-if="!targetAd.is_editable || approved"
                  pill
                  variant="danger"
                  class="badge-glow"
                >Non Editable</b-badge>
              </div>
              <div class="d-flex justify-content-center align-items-center mt-1 mx-2 mt-md-0">
                <span
                  v-if="approved !== null "
                  class="font-weight-bold"
                >
                  <span
                    v-if="!allowTimeForApproval"
                    class="font-small-3 approval-tag-class "
                  >Approved</span>
                  <span
                    v-else
                    class="font-small-3 completed-tag-class "
                  >Completed</span>
                </span>
                <span
                  v-else
                  class=" font-weight-bold"
                >
                  <span v-if="!allowTimeForApproval">
                    <span
                      v-if="targetAd.cancelled_by === null"
                      class="font-small-3 pending-tag-class"
                    >Pending</span>
                    <span
                      v-else
                      v-b-popover.hover.top="`${targetAd.reject_reason}`"
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      class="font-small-3 rejected-tag-class"
                      title="Rejection reason"
                    >Rejected
                    </span>
                  </span>
                  <span v-else>
                    <span
                      v-if="targetAd.cancelled_by === null"
                      class="font-small-3 expired-tag-class"
                    >Expired</span>
                    <span
                      v-else
                      v-b-popover.hover.top="`${targetAd.reject_reason}`"
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      title="Rejection reason"
                      class="font-small-3 rejected-tag-class"
                    >Rejected
                    </span>
                  </span>
                </span>
              </div>
              <div>
                <feather-icon
                  :id="id"
                  icon="EyeIcon"
                  class="cursor-pointer"
                  size="16"
                  style="margin: 8px"
                  @click="review($event)"
                />
                <b-tooltip
                  v-if="targetAd.cancelled_by && canEditTargetAdTimeCondition"
                  :target="'tooltip-icon-disable' + id"
                >
                  <span>Rejected Target Ads cannot be edited!</span>
                </b-tooltip>
                <b-tooltip
                  v-if="canEditTargetAdTimeCondition && !targetAd.cancelled_by && !approved"
                  :target="'tooltip-icon-disable' + id"
                >
                  <span>Expired Target Ads cannot be edited!</span>
                </b-tooltip>
                <b-tooltip
                  v-if="canEditTargetAdTimeCondition && !targetAd.cancelled_by && !approved && !allowTimeForApproval"
                  :target="'tooltip-icon-disable' + id"
                >
                  <span> Target Ad booked within 9 days cannot be edited! </span>
                </b-tooltip>
                <b-tooltip
                  v-if="canEditTargetAdCompletedCondition"
                  :target="'tooltip-icon-disable' + id"
                >
                  <span>Completed TargetAd cannot be edited! </span>
                </b-tooltip>
                <b-tooltip
                  v-if="canEditTargetAdApprovalCondition"
                  :target="'tooltip-icon-disable' + id"
                >
                  <span>Approved TargetAd cannot be edited! </span>
                </b-tooltip>
                <span :id="'tooltip-icon-disable' + id">
                  <feather-icon
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    v-b-tooltip.hover
                    icon="Edit2Icon"
                    :class="targetAd.cancelled_by || !targetAd.is_editable || approved? 'disableIcon':'null'"
                    size="16"
                    style="margin: 8px; cursor: pointer"
                    @click="editFunc($event, allowTime, targetAd.cancelled_by)"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </slot>
    </b-card-header>
    <div v-if="visible">
      <hr>
      <b-row>
        <b-col
          lg="2"
          md="12"
          sm="12"
        >
          <div class="device">
            Parameters
          </div>
        </b-col>
        <b-col
          class="location-class ml-1 ml-lg-0"
          lg="10"
          md="12"
          sm="12"
        >
          <div>Location</div>
          <div class="font-weight-normal">
            {{ targetAd.location_address }}
          </div>
        </b-col>
      </b-row>
    </div>
    <b-collapse
      :id="collapseItemID"
      v-model="visible"
      :accordion="accordion"
      role="tabpanel"
    >
      <b-card-body style="padding: 0 !important">
        <slot />
      </b-card-body>
    </b-collapse>
  </b-card>
</template>
<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCollapse,
  BLink,
  BImg,
  VBPopover,
  BSpinner,
  VBToggle,
  VBTooltip,
  BTooltip,
  BCol, BRow, BBadge,
} from 'bootstrap-vue'
import { required } from '@validations'
import { v4 as uuidv4 } from 'uuid'
import Ripple from 'vue-ripple-directive'
import 'vuesax/dist/vuesax.css'
import Vuesax from 'vuesax'
import Vue from 'vue'
import 'swiper/css/swiper.css'
import '@/assets/images/simiicons/edit.svg'
import moment from 'moment'
import TimeLimitCondition from '@/common/config'
import { getLowestTimeForTargetAdEditAble } from '@/common/global/functions'

Vue.prototype.moment = moment
Vue.use(Vuesax)
Vue.directive('ripple', Ripple)

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCol,
    BRow,
    BCollapse,
    BBadge,
    BLink,
    BImg,
    BSpinner,
    BTooltip,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
    'b-popover': VBPopover,
  },
  filters: {
    moment(date) {
      return moment.utc(date).format('DD-MM-YYYY, HH:mm')
    },
    targetAdsUptoTimeMoment(date) {
      return moment.utc(date).add(5, 'minutes').format('HH:mm')
    },
  },
  // eslint-disable-next-line vue/require-prop-types
  props: {
    title: {
      type: String,
      required: true,
    },
    time: {
      type: String,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    targetAd: {
      type: Object,
      required: true,
    },
    approved: {
      type: Object,
    },
  },
  data() {
    return {
      TimeLimitCondition,
      allowTime: false,
      allowTimeForApproval: false,
      currentTime: null,
      spinner: {},
      visible: false,
      collapseItemID: '',
      openOnHover: this.$parent.hover,
      lat: '',
      lng: '',
      locationAddress: '',
      // validation
      required,
    }
  },
  computed: {
    accordion() {
      return this.$parent.accordion
        ? `accordion-${this.$parent.collapseID}`
        : null
    },
    canEditTargetAdApprovalCondition: {
      get() {
        return this.approved
      },
      set() {
        //
      },
    },
    canEditTargetAdCompletedCondition: {
      get() {
        return this.approved && this.allowTime && this.allowTimeForApproval
      },
      set() {
        //
      },
    },
    canEditTargetAdTimeCondition: {
      get() {
        return this.allowTime || this.allowTimeForApproval
      },
      set() {
        //
      },
    },
  },
  created() {
    this.collapseItemID = uuidv4()
    this.visible = this.isVisible
  },
  mounted() {
    this.disabledTimeFunc()
  },
  methods: {
    disabledTimeFunc() {
      this.currentTime = moment.utc().format('YYYY-MM-DD[T]HH:mm ')
      const targetAdsTime = moment.utc(this.time).format('YYYY-MM-DD[T]HH:mm ')
      const lowestDateTime = getLowestTimeForTargetAdEditAble().format('YYYY-MM-DD[T]HH:mm')
      this.allowTime = targetAdsTime < lowestDateTime
      this.allowTimeForApproval = targetAdsTime < this.currentTime
    },
    collapseOpen(val, e) {
      e.preventDefault()
      e.stopPropagation()
      this.visible = val
    },
    collapseClose() {
      if (this.openOnHover) {
        this.visible = false
      }
    },
    editFunc(e, allowTime, cancelled) {
      e.preventDefault()
      e.stopPropagation()
      if (!allowTime && !(this.approved !== null) && !cancelled) {
        this.$emit('editTargetAds', { id: this.id })
      }
    },
    switchFunc(e) {
      e.preventDefault()
      e.stopPropagation()
      this.switchs = !this.switchs
    },
    async review(e) {
      e.preventDefault()
      e.stopPropagation()
      const allMedia = this.targetAd.target_ads_has_media.map(media => media.media)
      if (allMedia) {
        await this.$store.dispatch('lightBox/lightBoxData', allMedia)
      }
    },
  },
}
</script>
<style lang="scss">
.target-ad-collapse {
.switches {
  border-radius: 25px;
}

.device {
  font-size: 14px;
  letter-spacing: 0px;
  color: #414141;
  font-weight: 600;
  font-family: "Montserrat";
  padding-left: 15px;
}

.time {
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 21px;
  color: #414141;
  font-weight: 500;
  font-family: "Montserrat";
  margin-top: 4px;
  min-width: 240px;
}

.name-class {
  width: 300px !important;
  @media (max-width: 1000px) {
    //min-width: 200px;
    width: 200px !important;
  }
}

  .title-class {
    font-size: 16px;
    letter-spacing: 0px;
    font-weight: 600;
    font-family: "Montserrat";
  }
.disableIcon {
  color: #80808080;
}
  .location-class {
    font-size: 14px;
    letter-spacing: 0px;
    color: #414141;
    font-weight: 500;
    font-family: "Montserrat";
  }
}

</style>
