<template>
  <div class="target-ads">
    <Teleport to="#header-buttons">
      <v-select
        v-model="currentState"
        class="mr-1 w-m-20"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        label="value"
        :clearable="false"
        :searchable="false"
        :options="currentStateOptions"
      />
      <div>
        <b-button
          variant="primary"
          :disabled="targetAdsLoading"
          @click="addTargetAds"
        >
          Add Target Ad
        </b-button>
      </div>
    </Teleport>
    <div>
      <app-collapse
        id="faq-payment-qna"
        accordion
        type="margin"
      >
        <b-skeleton-wrapper :loading="targetAdsLoading">
          <template #loading>
            <b-skeleton width="100%" />
            <b-skeleton width="75%" />
            <b-skeleton width="50%" />
          </template>
          <div
            v-if="allTargetAds.length === 0"
            class="text-center mt-5 font-large-1 text-black-50"
          >No Ads Found</div>
          <TargetAdsCollapseItem
            v-for="(targetAds, index) in allTargetAds"
            :id="targetAds.id"
            :key="index"
            :approved="targetAds.approved_by"
            :title="targetAds.name"
            :time="targetAds.time"
            :target-ad="targetAds"
            icon="EditIcon"
            :src="null"
            @editTargetAds="editTargetAds"
          >
            <div class="heading-elements">
              <b-row>
                <b-col md="8">
                  <b-card class="ml-1 pr-0 mb-1 media-card">
                    <b-table
                      ref="mediaTable"
                      :items="targetAds.target_ads_has_media"
                      sticky-header="220px"
                      responsive
                      selectable
                      :select-mode="selectMode"
                      :fields="tableColumns"
                      show-empty
                      class="float: right"
                      th-class="text"
                      @row-selected="onRowSelected($event, index)"
                    >
                      <!-- Column: Media -->
                      <template #cell(MEDIA)="data">
                        <b-media no-body>
                          <span class="mr-2 ml-0">
                            {{ data.index + 1 }}
                          </span>
                          <b-media-aside class="mr-1 ml-0">
                            <b-img
                              :src="getThumbnailOfFile(data.item.media)"
                              class="image"
                              rounded
                              alt="data.json"
                            />
                          </b-media-aside>
                          <b-media-body class="my-auto">
                            <b-link>
                              <span class="media-body">
                                {{ data.item.media.name }}
                              </span>
                            </b-link>
                          </b-media-body>
                        </b-media>
                      </template>
                      <!-- Column: Duration -->
                      <template
                        #cell(DURATION)="data"
                        class="BannerMode"
                      >
                        <span class="text-nowrap">
                          {{ data.item.media.duration }}
                        </span>
                      </template>
                      <!-- Column: Type -->
                      <template
                        #cell(TYPE)="data"
                        class="BannerMode center"
                      >
                        <div style="text-align: center">
                          <b-img
                            width="30px"
                            height="30px"
                            style="padding: 7px"
                            :src="
                              data.item.media.type_main === MediaType.IMAGE
                                ? require('@/assets/images/simiicons/Image.svg')
                                : require('@/assets/images/simiicons/Video.svg')
                            "
                          />
                          <span class="text-nowrap">
                            {{ data.item.media.type }}
                          </span>
                        </div>
                      </template>
                    </b-table>
                  </b-card>
                </b-col>
                <b-col md="4">
                  <b-card class="mr-1 mb-1 adds-views-class">
                    <div class="d-block font-weight-bolder text-nowrap stats">
                      Ad Views
                    </div>
                    <div class="demo-vertical-spacing">
                      <div class="stats-div">
                        <label class="labels">Views</label>
                        <label class="float-right labels">{{
                          currentState.value === "Past"
                            ? targetAds.target_ad_view_count
                            : "--"
                        }}</label>
                      </div>
                      <div class="stats-div">
                        <label class="labels">Qualified Views</label>
                        <label class="float-right labels">{{
                          currentState.value === "Past"
                            ? targetAds.qualified_view_count
                            : "--"
                        }}</label>
                      </div>
                      <div class="stats-div">
                        <label class="labels">Cost Per Qualified View</label>
                        <label class="float-right labels">{{
                          "$" + perViewCost
                        }}</label>
                      </div>
                    </div>
                    <div class="d-block cost-class">
                      Total Cost
                      <label class="float-right labels">{{
                        currentState.value === "Past"
                          ? "$" + targetAds.qualified_view_count * perViewCost
                          : "--"
                      }}</label>
                    </div>
                  </b-card>
                </b-col>
                <!-- Column: Devices -->
                <b-col md="12">
                  <div class="device">
                    Devices
                  </div>
                  <div
                    v-if="!targetAds.target_ads_has_device.length"
                    style="text-align: center; font-size: 20px!important;"
                    class="font-weight-normal mb-2"
                  >
                    This information will only be available after ad is played successfully
                  </div>
                  <b-card
                    v-if="targetAds.target_ads_has_device.length"
                    class="ml-1 pr-0 mb-1 media-card"
                  >
                    <b-table
                      ref="mediaTable"
                      :items="targetAds.target_ads_has_device"
                      sticky-header="220px"
                      responsive
                      :fields="tableColumnsForDevices"
                      show-empty
                      class="float: right"
                      th-class="text"
                    >
                      <!-- Column: Name -->
                      <template #cell(NAME)="data">
                        <span class="mr-2 ml-0">
                          {{ data.index + 1 }}
                        </span>
                        <span class="text-nowrap">
                          {{ data.item.device.name }}
                        </span>
                      </template>
                      <!-- Column: Mac Address -->
                      <template #cell(MAC_ADDRESS)="data">
                        <span class="text-nowrap">
                          {{ data.item.device.mac_address }}
                        </span>
                      </template>
                      <!-- Column: Played At -->
                      <template
                        #cell(PLAYED_AT)="data"
                        class="BannerMode"
                      >
                        <span class="text-nowrap">
                          {{ convertTime(data.item.target_ad_played_at) }}
                        </span>
                      </template>
                      <!-- Column: Played At Duration -->
                      <template
                        #cell(TARGET_AD_PLAYED_DURATION)="data"
                        class="BannerMode"
                      >
                        <span class="text-nowrap">
                          {{ data.item.target_ad_played_duration }}
                        </span>
                      </template>
                      <!-- Column: Location -->
                      <template
                        #cell(LOCATION)="data"
                        class="BannerMode"
                      >
                        <span class="text-nowrap">
                          {{ convertLocation(data.item.device.location) }}
                        </span>
                      </template>
                    </b-table>
                  </b-card>
                </b-col>

              </b-row>
            </div>
          </TargetAdsCollapseItem>
        </b-skeleton-wrapper>
      </app-collapse>
    </div>
    <div
      v-if="allTargetAds.length"
      class="mt-1"
    >
      <CustomPagination
        :total="paginationObject.total"
        :per-page="paginationObject.limit"
        :page="paginationObject.offset"
        @perPageChanged="perPageChanged"
        @pageChanged="pageChanged"
      />
    </div>
  </div>
</template>
<script>
import {
  BMedia,
  BLink,
  BTable,
  BImg,
  BMediaBody,
  BMediaAside,
  BSkeletonWrapper,
  BSkeleton,
  VBTooltip,
  BRow,
  BCol,
  BCard,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css'
import Vue from 'vue'
import vSelect from 'vue-select'
import Teleport from 'vue2-teleport'
import moment from 'moment'
import AppCollapse from '@/components/app-collapse/AppCollapse.vue'
import TargetAdsCollapseItem from '@/components/target-ads/TargetAdsCollapseItem.vue'
import MediaType from '@/common/enums/mediaTypeEnum'
import { getThumbnailOfFile, showErrorMessage, showToast } from '@/common/global/functions'
import store from '@/store'
import MemberPermissions from '@/common/enums/memberPermissionsEnum'
import TimeLimitCondition from '@/common/config'
import CustomPagination from '@/components/common/CustomPagination.vue'

Vue.use(Vuesax)
Vue.directive('ripple', Ripple)
export default {
  name: 'Index',
  components: {
    CustomPagination,
    TargetAdsCollapseItem,
    AppCollapse,
    BTable,
    BMedia,
    BLink,
    BImg,
    BMediaBody,
    BMediaAside,
    BSkeletonWrapper,
    BSkeleton,
    BRow,
    BCol,
    BCard,
    BButton,
    vSelect,
    Teleport,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  beforeRouteEnter(to, from, next) {
    const memberPermission = store.getters['user/getPermissionOfMember'](
      MemberPermissions.TARGET_ADS,
    )
    if (!memberPermission) {
      next({
        name: 'dashboard',
      })
    } else {
      next()
    }
  },
  data() {
    return {
      paginationObject: {
        offset: 1,
        limit: 10,
        total: 0,
      },
      currentState: { key: 1, value: 'Future' },
      timeState: 'future',
      MediaType,
      targetAdsLoading: false,
      id: '',
      allTargetAds: [],
      selectMode: 'single',
      tableColumns: [
        { key: 'MEDIA' },
        { key: 'DURATION' },
        { key: 'TYPE', thClass: 'text-center' },
      ],
      tableColumnsForDevices: [
        { key: 'NAME' },
        { key: 'MAC_ADDRESS' },
        { key: 'PLAYED_AT' },
        { key: 'TARGET_AD_PLAYED_DURATION' },
        { key: 'LOCATION' },
      ],
      perViewCost: TimeLimitCondition.costPerView,
      currentStateOptions: [
        { key: 1, value: 'Future' },
        { key: 2, value: 'Past' },
      ],
    }
  },
  watch: {
    currentState: {
      handler(value) {
        if (value.value === 'Past') {
          this.timeState = 'past'
        } else {
          this.timeState = 'future'
        }
        this.pageChanged(1)
      },
    },
  },
  mounted() {
    this.getAllTargetAds(this.paginationObject)
  },
  methods: {
    convertTime(obj) {
      return !obj ? '' : moment(Number(obj)).utc().format('ddd DD MMM yyyy HH:mm')
    },
    convertLocation(location) {
      const [lng, lat] = location?.split('(')?.[1].split(' ')
      return `lng = ${lng}, lat = ${lat.replace(')', '')}`
    },
    addTargetAds() {
      this.$router.push('/target-ads/add')
    },
    getThumbnailOfFile(file) {
      return getThumbnailOfFile(file)
    },
    perPageChanged(limit) {
      this.paginationObject.offset = 1
      this.paginationObject.limit = limit
      this.getAllTargetAds(this.paginationObject)
    },
    pageChanged(offset) {
      this.paginationObject.offset = offset
      this.getAllTargetAds(this.paginationObject)
    },
    async getAllTargetAds({ offset, limit }) {
      this.targetAdsLoading = true
      try {
        const res = await this.$axios.get(
          `target-ads?offset=${offset - 1}&limit=${limit}&search_query=${
            this.timeState
          }`,
        )
        const {
          data: { data },
        } = res || {}
        this.allTargetAds = data.results
        this.paginationObject.total = data.total
        this.targetAdsLoading = false
      } catch ({
        response: {
          data: { statusCode, message },
        },
      }) {
        if (showErrorMessage(statusCode)) {
          showToast('Target Ads', 'You are not authorized for this action!', 'danger')
          this.targetAdsLoading = false
        }
      } finally {
        this.targetAdsLoading = false
      }
    },
    editTargetAds(obj) {
      this.$router.push(`/target-ads/edit-target-ad/${obj.id}`)
    },
    async onRowSelected(items, index) {
      if (items[0] && items[0].media) {
        const mediaArray = Object.assign([], [items[0].media])
        await this.$store.dispatch('lightBox/lightBoxData', mediaArray)
      }
      await this.$refs.mediaTable[index].clearSelected()
    },
  },
}
</script>

<style lang="scss">
.target-ads {
  #time-state-select {
    .vs__selected-options {
      min-width: 100px !important;
    }
    .vs__dropdown-menu {
      min-width: 100px !important;
    }
    .vs__dropdown-toggle {
      min-width: 100px !important;
      background-color: white !important;
    }
  }
  .vs__dropdown-toggle {
    background-color: white !important;
  }
  #per-page-selector {
    .vs--single .vs__dropdown-toggle {
      max-height: 37px !important;
    }
    .vs__dropdown-toggle {
      min-width: 90px !important;
    }
  }
  .image {
    width: 32px;
    height: 32px;
    border: 2px solid#ececec;
    border-radius: 5px !important;
    box-shadow: 0 1px 7px#0000002e;
  }
  .demo-vertical-spacing {
    margin-bottom: 5px;
  }
  .switches {
    border-radius: 25px;
  }
  .labels {
    font-size: 14px;
    letter-spacing: 0px;
    color: #414141;
    font-weight: 500;
    font-family: "Montserrat";
    line-height: 2;
  }
  .stats-div {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .stats {
    font-size: 14px;
    letter-spacing: 0px;
    color: #1f58b5;
    font-weight: 600;
    font-family: "Montserrat";
  }
  .media-card {
    max-height: 225px !important;
    min-height: 225px !important;
  }
  .adds-views-class {
    min-height: 225px;
  }
  .per-page-selector {
    width: 90px;
  }
  .cost-class {
    font-size: 14px;
    letter-spacing: 0px;
    color: #414141;
    font-weight: 600;
    font-family: "Montserrat";
  }
  .top-margin-class {
    margin-top: -3.5rem;
    @media (max-width: 848px) {
      margin-top: 0px !important;
    }
  }
}
</style>
